import React, { Suspense, lazy } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { isMobileOnly } from 'react-device-detect';

import { FaRegAddressCard } from 'react-icons/fa';
import { AuditOutlined, BulbOutlined, ContactsOutlined, DeleteOutlined, DownOutlined, EditOutlined, FormOutlined, ToolOutlined } from '@ant-design/icons';
import { Row, Col, Dropdown, Button, Modal, message, Result, Descriptions, Collapse, Card, Skeleton } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import type { PageContainerProps } from '@ant-design/pro-layout';
import type { MenuInfo } from 'rc-menu/lib/interface';
import type { BreadcrumbProps } from 'antd/lib/breadcrumb';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';

import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import { ILoadingFailureState, ILoadingState } from 'models/props-or-state/states';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

import { GlobalState } from 'store';
import { getSelectedOrg } from 'store/selectors/org';
import { getInventoryById } from 'store/selectors/inventories';
import { inventoryDeletedEventRecieved, fetchInventory } from 'store/actions/inventories';
import { hasAccessToFeature } from 'store/selectors/permissions';

import { InventoryCategory, InventoryStatus, InventoryType } from 'models/inventory';
import { INote } from 'models/notes';
import { RelatedToType } from 'models/common/relatedTo';

import { Loading } from 'components/misc/loading';
import { LongCurrency } from 'utils/formatting';
import { breadCrumbItemRender } from 'utils/breadCrumbs';
import { displayErrorNotification } from 'utils/errors';

import { InventoryStatusTag } from 'components/inventories/inventoryStatusTag';
import { InventoryRenameModal } from 'components/inventories/renameModal';
import { EditInventoryDetailsDrawer } from 'components/inventories/editDetailsDrawer';
import { InventoryTaxTable } from 'components/taxes/taxTable';
import { TractsTable } from 'components/tracts/tractsTable';
import { NotesCard } from 'components/notes';
import { FilesCard } from 'components/files';
import { Address } from 'components/misc/address';
import { EntityDescriptionCard } from 'components/entity/entityDescriptionCard';

import { deleteInventoryByID, getInventoryNotes, setInventoryStatus, verifyInventoryAddress } from 'api/inventories';

import './index.css';
import { AccessControlledButton } from 'components/permissions';

const DescriptionCard = lazy(() => import('components/misc/descriptionCard'));
const UnitsTable = lazy(() => import('components/units/unitsTable'));

interface IInventoryPathParams extends IOrgIdPathParams {
    inventoryId: string;
}

const mapStateToProps = (state: GlobalState, props: RouteComponentProps<IInventoryPathParams>) => ({
    selectedOrg: getSelectedOrg(state),
    inventory: getInventoryById(state, props.match.params.inventoryId),
    hasAccessTo: hasAccessToFeature(PermissionFeature.Inventory)(state),
});

interface IInventoryProps extends ReturnType<typeof mapStateToProps>, DispatchProp, RouteComponentProps<IInventoryPathParams> {}

interface IInventoryState extends ILoadingFailureState, ILoadingState {
    notes: INote[];
    renameVisible: boolean;
    editDetailsVisible: boolean;
    verifyingAddress: boolean;
}

class InventoryBase extends React.PureComponent<IInventoryProps, IInventoryState> {
    state: Readonly<IInventoryState> = {
        notFound: false,
        otherError: false,
        error: '',
        isLoading: true,
        notes: [],
        renameVisible: false,
        editDetailsVisible: false,
        verifyingAddress: false,
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({ isLoading: true, notFound: false, otherError: false });
        const { orgId, inventoryId } = this.props.match.params;

        try {
            await this.props.dispatch(fetchInventory(inventoryId) as any);
            const notes = await getInventoryNotes(orgId, inventoryId);

            this.setState({
                isLoading: false,
                notes,
            });
        } catch (e: any) {
            console.error('Error while loading the inventory:', e);

            if (e.code === 73) {
                this.setState({ notFound: true, isLoading: false });
            } else {
                this.setState({
                    notFound: false,
                    otherError: true,
                    error: typeof e.error === 'string' ? e.error : 'Unknown error',
                    isLoading: false,
                });
            }
        }
    }

    componentDidUpdate(prevProps: IInventoryProps) {
        if (this.props.match.params.orgId === prevProps.match.params.orgId) {
            return;
        }

        this.loadData();
    }

    //#region operation menu drop down
    closeRenameModal = () => {
        this.setState({ renameVisible: false });
    }

    closeEditDetailsModal = async (saved: boolean) => {
        this.setState({ editDetailsVisible: false });

        if (saved) {
            this.loadData();
        }
    }

    onOperationsMenuClick = async (e: MenuInfo) => {
        const { orgId, inventoryId } = this.props.match.params;

        let loanId = '';
        if (this.props.inventory?.residentialDetails?.loan?.id) {
            loanId = this.props.inventory?.residentialDetails?.loan?.id || '';
        }

        let clientId = '';
        if (this.props.inventory?.residentialDetails?.owner?.id) {
            clientId = this.props.inventory?.residentialDetails?.owner?.id || '';
        }

        let rentalId = '';
        if (this.props.inventory?.residentialDetails?.rental?.id) {
            rentalId = this.props.inventory?.residentialDetails?.rental?.id || '';
        }

        switch (e.key) {
            case 'details-client':
                this.props.history.push(`/${ orgId }/clients/${ clientId }`);
                return;
            case 'details-loan':
                this.props.history.push(`/${ orgId }/loans/${ loanId }`);
                return;
            case 'details-rental':
                this.props.history.push(`/${ orgId }/rentals/${ rentalId }`);
                return;
            case 'rename':
                this.setState({ renameVisible: true });
                return;
            case 'delete':
                Modal.confirm({
                    title: 'You sure about deleting this inventory?',
                    content: 'Please note, deleting this inventory will result in the removal of all of its content from the entire platform, including history.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk: async () => {
                        try {
                            await deleteInventoryByID(orgId, inventoryId);
                        } catch (e) {
                            displayErrorNotification(e);
                            Modal.destroyAll();
                            return;
                        }

                        await this.props.dispatch(inventoryDeletedEventRecieved({ id: inventoryId }));
                        this.props.history.push(`/${orgId}/inventories`);
                    },
                });
                return;
            case InventoryStatus.Idea:
                await setInventoryStatus(orgId, inventoryId, InventoryStatus.Idea);
                message.success('Successfully updated the status to: Idea');
                return;
            case InventoryStatus.Ready:
                await setInventoryStatus(orgId, inventoryId, InventoryStatus.Ready);
                message.success('Successfully updated the status to: Ready');
                return;
            case InventoryStatus.InProgress:
                await setInventoryStatus(orgId, inventoryId, InventoryStatus.InProgress);
                message.success('Successfully updated the status to: In Progress');
                return;
            case InventoryStatus.Completed:
                message.success('Successfully updated the status to: Completed');
                await setInventoryStatus(orgId, inventoryId, InventoryStatus.Completed);
                return;
            default:
                message.info(`Clicked on the following menu item: ${e.key}`);
                return;
        }
    }

    get operationsMenuItems(): Array<ItemType> {
        const { inventory } = this.props;

        let hasLoan = false;
        if (inventory?.residentialDetails?.loan?.id) {
            hasLoan = true
        }

        let hasClient = false;
        if (inventory?.residentialDetails?.owner?.id) {
            hasClient = true;
        }

        let hasRental = false;
        if (inventory?.residentialDetails?.rental?.id) {
            hasRental = true;
        }

        return [
            hasClient ? { key: 'details-client', icon: <ContactsOutlined />, label: 'View Client' } : null,
            hasLoan ? { key: 'details-loan', icon: <AuditOutlined />, label: 'View Loan' } : null,
            hasRental ? { key: 'details-rental', icon: <AuditOutlined />, label: 'View Rental' } : null,
            { key: 'rename', icon: <FormOutlined />, label: 'Rename', disabled: !this.props.hasAccessTo.update },
            { key: 'delete', icon: <DeleteOutlined />, label: 'Delete', disabled: (inventory?.category === InventoryCategory.Residential && inventory.residentialDetails?.loan?.id !== '') || !this.props.hasAccessTo.delete },
            {
                key: 'status', label: <React.Fragment><BulbOutlined /> Status</React.Fragment>,
                disabled: !this.props.hasAccessTo.update,
                children: [
                    { key: InventoryStatus.Idea, label: 'Idea', disabled: inventory?.status === InventoryStatus.Idea },
                    { key: InventoryStatus.Ready, label: 'Ready', disabled: inventory?.status === InventoryStatus.Ready },
                    { key: InventoryStatus.InProgress, label: 'In Progress', disabled: inventory?.status === InventoryStatus.InProgress },
                    { key: InventoryStatus.Completed, label: 'Completed', disabled: inventory?.status === InventoryStatus.Completed },
                ],
            },
        ];
    }
    //#endregion

    refreshNotes = async () => {
        const notes = await getInventoryNotes(this.props.match.params.orgId, this.props.match.params.inventoryId);

        this.setState({ notes });
    }

    get breadcrumbProps(): BreadcrumbProps {
        if (!this.props.selectedOrg || !this.props.inventory || !this.props.inventory.id) {
            return {};
        }

        return {
            itemRender: breadCrumbItemRender,
            items: [
                {
                    path: `/${ this.props.selectedOrg.shortId }`,
                    breadcrumbName: 'Dashboard',
                },
                {
                    path: `/${ this.props.selectedOrg.shortId }/inventories`,
                    breadcrumbName: `${this.props.selectedOrg.name} Inventories`,
                },
                {
                    path: `/${ this.props.selectedOrg.shortId }/inventories/${ this.props.inventory.id }`,
                    breadcrumbName: this.props.inventory.name || 'Untitled',
                },
            ],
        };
    }

    get headerDescriptions() {
        if (!this.props.inventory) {
            return null;
        }

        if (this.props.inventory.category === InventoryCategory.Multifamily) {
            return (
                <Descriptions size="small" column={isMobileOnly ? 2 : 3} layout={isMobileOnly ? 'vertical' : 'horizontal'}>
                    <Descriptions.Item label="Address">{ this.props.inventory?.address?.streetAddresses?.length > 0 ? this.props.inventory.address.streetAddresses[0] : '-' }</Descriptions.Item>
                    <Descriptions.Item label="City">{ this.props.inventory?.address?.city ? this.props.inventory.address.city : '-' }</Descriptions.Item>
                    <Descriptions.Item label="County">{ this.props.inventory?.address?.county ? this.props.inventory.address.county : '-' }</Descriptions.Item>
                    <Descriptions.Item label="State"><span className="all-caps">{ this.props.inventory?.address?.state ? this.props.inventory.address.state : '-' }</span></Descriptions.Item>
                    <Descriptions.Item label="Zip Code">{ this.props.inventory?.address?.zipCode ? this.props.inventory.address.zipCode : '-' }</Descriptions.Item>
                    <Descriptions.Item label="Units">{ this.props.inventory.multifamilyDetails?.units || '-' }</Descriptions.Item>
                    <Descriptions.Item label="Year Built">{ this.props.inventory.multifamilyDetails?.yearBuilt || '-' }</Descriptions.Item>
                </Descriptions>
            );
        }

        if (!this.props.inventory.landDetails) {
            return null;
        }

        return (
            <Descriptions size="small" column={isMobileOnly ? 2 : 3} layout={isMobileOnly ? 'vertical' : 'horizontal'}>
                <Descriptions.Item label="County">{ this.props.inventory?.address?.county ? this.props.inventory.address.county : '-' }</Descriptions.Item>
                <Descriptions.Item label="State"><span className="all-caps">{ this.props.inventory?.address?.state ? this.props.inventory.address.state : '-' }</span></Descriptions.Item>
                <Descriptions.Item label="Total Acres">{ this.props.inventory.landDetails.totalAcres.toFixed(2) }</Descriptions.Item>
                <Descriptions.Item label="Total Dev Cost"><LongCurrency value={this.props.inventory.landDetails.costTotal} /></Descriptions.Item>
                <Descriptions.Item label="Cost Per Acre"><LongCurrency value={this.props.inventory.landDetails.costPerAcre} /></Descriptions.Item>
                { Array.isArray(this.props.inventory.landDetails.parcelNumbers) ?
                    <Descriptions.Item label="Parcel Numbers">{ this.props.inventory.landDetails.parcelNumbers.length === 0 ? '-' : this.props.inventory.landDetails.parcelNumbers.join(', ') }</Descriptions.Item>
                : null }
            </Descriptions>
        );
    }

    get headerExtra() {
        return (
            <Button.Group>
                <Dropdown menu={{ items: this.operationsMenuItems, onClick: this.onOperationsMenuClick }} trigger={['click', 'hover']}>
                    <Button type="dashed" key="operations" icon={<ToolOutlined />}>Operation <DownOutlined /></Button>
                </Dropdown>
                <AccessControlledButton
                    icon={<EditOutlined />}
                    onClick={() => this.setState({ editDetailsVisible: true })}
                    feature={PermissionFeature.Inventory}
                    action={PermissionAction.Update}
                    prevent="tooltip"
                >Edit</AccessControlledButton>
            </Button.Group>
        );
    }

    get tractsAndPropertyTax() {
        if (!this.props.selectedOrg || !this.props.inventory || this.props.inventory.category !== InventoryCategory.Land) {
            return null;
        }

        return (
            <Collapse defaultActiveKey={['tracts']} bordered={false}>
                <Collapse.Panel key="tracts" header="Tracts">
                    <TractsTable inventory={this.props.inventory} isLoading={this.state.isLoading} enablePricing={false} pageSize={99} />
                </Collapse.Panel>

                <Collapse.Panel key="property-tax" header="Property Tax per Year">
                    <InventoryTaxTable orgId={this.props.selectedOrg.id} inventory={this.props.inventory} />
                </Collapse.Panel>
            </Collapse>
        );
    }

    onVerifyResidentialAddressClick = () => {
        this.setState({ verifyingAddress: true }, async () => {
            if (!this.props.selectedOrg || !this.props.inventory) {
                return;
            }

            try {
                await verifyInventoryAddress(this.props.selectedOrg.id, this.props.inventory.id);
            } catch (e) {
                displayErrorNotification(e);
            } finally {
                this.setState({ verifyingAddress: false });
            }
        });
    }

    get verifyAddressButton() {
        if (this.props.inventory!.address.verified) {
            return null;
        }

        return (
            <AccessControlledButton
                type="dashed"
                size="small"
                icon={<FaRegAddressCard />}
                onClick={this.onVerifyResidentialAddressClick}
                disabled={this.state.isLoading || this.state.verifyingAddress}
                loading={this.state.verifyingAddress}
                feature={PermissionFeature.Inventory}
                action={PermissionAction.Update}
                prevent="hide"
            >
                Verify Address
            </AccessControlledButton>
        );
    }

    onEditResidentialDetailsClick = () => this.setState({ editDetailsVisible: true });

    get residentialArea() {
        //TODO: break this out into it's own component so we can lazy load it

        const { inventory } = this.props;
        if (!this.props.selectedOrg || !inventory || inventory.category !== InventoryCategory.Residential || !inventory.residentialDetails) {
            return null;
        }

        return (
            <Row gutter={16} className="residential-details">
                <Col span={isMobileOnly ? 12 : 8}>
                    <Card title="Address" bordered={false} extra={this.verifyAddressButton}>
                        <Address value={inventory.address} showTags />
                    </Card>
                </Col>

                <Col span={isMobileOnly ? 12 : 8}>
                    <Card
                        title="Details"
                        bordered={false}
                        extra={
                            <AccessControlledButton
                                type="dashed"
                                size="small"
                                onClick={this.onEditResidentialDetailsClick}
                                icon={<EditOutlined />}
                                feature={PermissionFeature.Inventory}
                                action={PermissionAction.Update}
                                prevent="hide"
                            >Edit</AccessControlledButton>
                        }
                    >
                        <Descriptions size="small" column={2} layout={isMobileOnly ? 'vertical' : 'horizontal'}>
                            { Array.isArray(inventory.residentialDetails.parcelNumbers) ?
                                <Descriptions.Item label="Parcel Numbers" span={2}>{ inventory.residentialDetails.parcelNumbers.length === 0 ? '-' : inventory.residentialDetails.parcelNumbers.join(', ') }</Descriptions.Item>
                            : null }
                            <Descriptions.Item label="Bedrooms">{ inventory.residentialDetails.bedrooms }</Descriptions.Item>
                            <Descriptions.Item label="Bathrooms">{ inventory.residentialDetails.bathrooms }</Descriptions.Item>
                            <Descriptions.Item label="Square Footage">{ inventory.residentialDetails.livingSquareFootage }</Descriptions.Item>
                            <Descriptions.Item label="Lot Area" className="title-caps">{ inventory.residentialDetails.lotArea } { inventory.residentialDetails.lotAreaUnit }</Descriptions.Item>
                            <Descriptions.Item label="Price"><LongCurrency value={inventory.residentialDetails.price} tooltip={false} /></Descriptions.Item>
                            { inventory.type === InventoryType.Rental ? <Descriptions.Item label="Rent"><LongCurrency value={inventory.residentialDetails.rent} /></Descriptions.Item> : null }
                        </Descriptions>
                    </Card>
                </Col>

                <Col span={isMobileOnly ? 12 : 8}>
                    <EntityDescriptionCard
                        title="Buyer's Information"
                        emptyContent="No buyer yet."
                        clientId={inventory.residentialDetails.owner?.id}
                        vertical
                    />
                </Col>

                <Col span={24} style={{ paddingTop: isMobileOnly ? 'initial' : '16px'}}>
                    <Suspense fallback={<Skeleton.Input block active />}>
                        <DescriptionCard orgId={this.props.selectedOrg.id} inventory={inventory} refresh={this.loadData} />
                    </Suspense>
                </Col>
            </Row>
        );
    }

    get multiFamilyArea() {
        const { inventory } = this.props;
        if (!this.props.selectedOrg || !inventory || inventory.category !== InventoryCategory.Multifamily) {
            return null;
        }

        return (
            <Suspense fallback={<Skeleton active />}>
                <UnitsTable inventory={inventory} />
            </Suspense>
        );
    }

    onBack = () => this.props.history.push(`/${this.props.match.params.orgId}/inventories`);

    get loadedLayout() {
        if (!this.props.inventory) {
            return null;
        }

        const headerProps: PageContainerProps = {
            title: this.props.inventory.name,
            subTitle: <span className="title-caps">{ this.props.inventory.category } ({ this.props.inventory.type })</span>,
            tags: <InventoryStatusTag status={this.props.inventory.status} />,
            content: this.headerDescriptions,
            extra: this.headerExtra,
            onBack: this.onBack,
            breadcrumb: this.breadcrumbProps,
            className: isMobileOnly ? 'mobile' : '',
        };

        return (
            <PageContainer {...headerProps}>
                <Row gutter={24} style={{ marginTop: 24 }}>
                    <Col span={24}>
                        { this.props.inventory.category === InventoryCategory.Land ? this.tractsAndPropertyTax : null }
                        { this.props.inventory.category === InventoryCategory.Residential ? this.residentialArea : null }
                        { this.props.inventory.category === InventoryCategory.Multifamily ? this.multiFamilyArea : null }
                    </Col>
                </Row>

                <Row gutter={24} style={{ marginTop: 24 }}>
                    <Col xs={24} lg={12}>
                        <NotesCard orgId={this.props.match.params.orgId} isLoading={this.state.isLoading} inventory={this.props.inventory} notes={this.state.notes} refresh={this.refreshNotes} />
                    </Col>

                    <Col xs={24} lg={12}>
                        <FilesCard
                            orgId={this.props.match.params.orgId}
                            relatedTo={{
                                type: RelatedToType.INVENTORY,
                                id: this.props.inventory.id,
                            }}
                            relatedLabel={this.props.inventory.name}
                        />
                    </Col>
                </Row>

                <InventoryRenameModal
                    visible={this.state.renameVisible}
                    orgId={this.props.match.params.orgId}
                    inventoryId={this.props.inventory.id}
                    initialName={this.props.inventory.name}
                    close={this.closeRenameModal}
                />

                <EditInventoryDetailsDrawer inventory={this.props.inventory} visible={this.state.editDetailsVisible} close={this.closeEditDetailsModal} />
            </PageContainer>
        );
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }

        if (this.state.notFound) {
            return (
                <Result
                    status="404"
                    title="404"
                    subTitle="We searched but failed to find that inventory. 🤔"
                    extra={<Button type="primary" onClick={this.onBack}>Back to Inventory List</Button>}
                />
            );
        }

        return this.loadedLayout;
    }
}

export const Inventory = withRouter(connect(mapStateToProps)(InventoryBase));
